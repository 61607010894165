import {DonorOpl, CordOpl} from '@matchsource/api-generated/orders';
import {CordSearchResultModel, DonorSearchResultModel} from '@matchsource/models/search-results';
import {OplCreateModel, OplPanelSimpleModel, OplSourceTypes} from '@matchsource/models/opl';
import {OrderableStatusModel} from '@matchsource/models/order-shared';

interface GetOplActionParams {
  requestVaccinationDetails?: boolean;
  repeatedRequest?: boolean;
}

interface GetDefaultOplActionParams {
  isSsa?: boolean;
  requestVaccinationDetails?: boolean;
}

export class GetOpls {
  static readonly type = '[Opl] Get Opls Sources';

  constructor(
    public readonly recipientGuid: MsApp.Guid,
    public readonly sourceType: OplSourceTypes
  ) {}
}

export class GetOpl {
  static readonly type = '[Opl] Get Opl Source';

  readonly requestVaccinationDetails: boolean;
  readonly repeatedRequest: boolean;

  constructor(
    public readonly id: number,
    public readonly sourceType: string,
    params?: GetOplActionParams
  ) {
    this.requestVaccinationDetails = !!params?.requestVaccinationDetails;
    this.repeatedRequest = !!params?.repeatedRequest;
  }
}

export class GetSsaOpl {
  static readonly type = '[Opl] Get SSA Opl';

  readonly requestVaccinationDetails: boolean;
  readonly repeatedRequest: boolean;

  constructor(
    public readonly id: number,
    public readonly sourceType: string,
    params?: GetOplActionParams
  ) {
    this.requestVaccinationDetails = !!params?.requestVaccinationDetails;
    this.repeatedRequest = !!params?.repeatedRequest;
  }
}

export class GetDefaultOpl {
  static readonly type = '[Opl] Get Default Opl Source';

  readonly requestVaccinationDetails: boolean;
  readonly isSsa?: boolean;

  constructor(
    public readonly recipientGuid: string,
    public readonly sourceType: OplSourceTypes,
    params?: GetDefaultOplActionParams
  ) {
    this.requestVaccinationDetails = !!params?.requestVaccinationDetails;
    this.isSsa = !!params?.isSsa;
  }
}

export class UpdateOpl {
  static readonly type = '[Opl] Update Opl Source';

  constructor(
    public readonly id: number,
    public readonly opl: OplPanelSimpleModel,
    public readonly sourceType: string
  ) {}
}

export class UpdateDefaultOpl {
  static readonly type = '[Opl] Update Default Opl Source';

  constructor(
    public readonly id: number,
    public readonly recipientGuid: MsApp.Guid,
    public readonly sourceType: OplSourceTypes
  ) {}
}

export class RemoveOplSource {
  static readonly type = '[Opl] Remove Opl Source Source';

  constructor(
    public readonly id: number,
    public readonly guid: string,
    public readonly sourceType: string
  ) {}
}

export class DeleteOpl {
  static readonly type = '[Opl] Delete Opl Source';

  constructor(
    public readonly id: number,
    public readonly recipientGuid: MsApp.Guid,
    public readonly sourceType: string
  ) {}
}

export class RemoveOplSources {
  static readonly type = '[Opl] Remove All Opl Sources';

  constructor(
    public readonly id: number,
    public readonly sourceType: string
  ) {}
}

export class CreateOpl {
  static readonly type = '[Opl] Create Opl Source';

  constructor(
    public readonly opl: OplCreateModel,
    public readonly recipientGuid: MsApp.Guid,
    public readonly sourceType: string
  ) {}
}

export class AddSourceToOPL {
  static readonly type = '[Opl] Add Source To Opl';

  constructor(
    public readonly source: DonorOpl | CordOpl,
    public readonly oplId: number,
    public readonly oplSource: DonorSearchResultModel | CordSearchResultModel
  ) {}
}

export class CreateOplWithSource {
  static readonly type = '[Opl] Create OPl With Source Source';

  constructor(
    public readonly opl: OplCreateModel,
    public readonly recipientGuid: MsApp.Guid,
    public readonly source: DonorOpl | CordOpl
  ) {}
}

export class AddSourceToExistedOpl {
  static readonly type = '[Opl] Add New Source To Existed OPl Source';

  constructor(
    public readonly source: DonorOpl | CordOpl,
    public readonly oplId: number
  ) {}
}

export class ClearOpl {
  static readonly type = '[Opl] Clear Opl State';
}

export class ClearOplVaccinationDetails {
  static readonly type = '[Opl] Clear Vaccination Details';
}

export class UpdateOplSourceOrderableStatus {
  static readonly type = '[Opl] Update Source Orderable Status';

  constructor(
    public readonly sourceId: MsApp.Guid,
    public readonly orderableStatus: OrderableStatusModel,
    public readonly statusOverride?: string
  ) {}
}
