import {Injectable} from '@angular/core';
import {toMap} from '@matchsource/utils';
import orderBy from 'lodash-es/orderBy';
import {StateContext} from '@ngxs/store';
import cloneDeep from 'lodash-es/cloneDeep';
import {OplStateModel} from 'app/features/search-results/shared/store/opl/opl.state.model';
import {
  OplPanelModel,
  OplDonorSourceModelClass as OplDonorSourceModel,
  OplCordSourceModelClass as OplCordSourceModel,
} from '@matchsource/models/opl';
import {OPL_SOURCE_TYPES} from 'app/shared';
import {DonorOpl, CordOpl} from '@matchsource/api-generated/orders';
import {CordSearchResultModel, DonorSearchResultModel} from '@matchsource/models/search-results';
import {MatchResultsService} from '@matchsource/source-factories';
import {Observable} from 'rxjs';
import {CordListModel} from '@matchsource/models/cord';
import {GetOrderableSourcesResponse} from '@matchsource/models/order';
import {DonorListModel} from '@matchsource/models/donor';
import {BloodSourceType} from '@matchsource/models/source';

@Injectable({
  providedIn: 'root',
})
export class OplSourceService {
  constructor(private readonly matchResultService: MatchResultsService) {}

  filterOplSources(opl: OplPanelModel, guid: MsApp.Guid, sourceType: string) {
    opl[sourceType] = opl[sourceType].filter((item: OplDonorSourceModel | OplCordSourceModel) => item.guid !== guid);
    return opl;
  }

  formatSources(orderableSources: GetOrderableSourcesResponse, opl: OplPanelModel, sourceType: string) {
    const sourcesMap = toMap(
      orderableSources.map(source => source),
      'id'
    );
    const sources = opl[sourceType].map((source: OplDonorSourceModel | OplCordSourceModel) => ({
      ...source,
      source: sourcesMap[source.guid],
    }));
    return {
      ...opl,
      [sourceType]: orderBy(sources, 'orderNum'),
    };
  }

  updateOpls(state: StateContext<OplStateModel>, opl: OplPanelModel) {
    const {opls} = state.getState();
    let copy = cloneDeep(opls);
    copy = (copy || []).map(item => {
      if (item.id === opl.id) {
        item = opl;
        const length = item.cords.length + item.donors.length;
        item.nameNcounter = `${opl.name} (${length})`;
      }
      return item;
    });
    return copy;
  }

  fillOplWithSources(
    item: OplPanelModel,
    state: StateContext<OplStateModel>,
    source: DonorOpl | CordOpl,
    oplSource: DonorSearchResultModel | CordSearchResultModel
  ) {
    const bloodType = OPL_SOURCE_TYPES[source.sourceType];
    const {opl} = state.getState();
    const oplCopy = cloneDeep(opl);
    const sourcesToUse = item[bloodType];
    let newSource = sourcesToUse.find(
      (sourceToUse: OplDonorSourceModel | OplCordSourceModel) => sourceToUse.guid === source.guid
    );
    const cloneSource = cloneDeep(oplSource);

    newSource =
      bloodType === BloodSourceType.Donors ? new OplDonorSourceModel(newSource) : new OplCordSourceModel(newSource);
    newSource = {
      ...newSource,
      source: {
        ...cloneSource,
      },
    };
    oplCopy[bloodType].push(newSource);
    return oplCopy;
  }

  getSources(opl: OplPanelModel, sourceType: string): Observable<DonorListModel[] | CordListModel[]> {
    return sourceType === BloodSourceType.Donors
      ? this.matchResultService.getDonors(
          opl.patientId,
          opl.donors.map(source => source.guid)
        )
      : this.matchResultService.getCords(
          opl.patientId,
          opl.cords.map(source => source.guid)
        );
  }
}
