import {isLetter, isDigit, isSpecial} from '@matchsource/utils';

import {OplPanelModel} from '@matchsource/models/opl';

const calculateSymbolWeight = (symbol: string): number => {
  if (isSpecial(symbol)) {
    return 1;
  }

  if (isDigit(symbol)) {
    return 2;
  }

  if (isLetter(symbol)) {
    return 3;
  }

  return 0;
};

export const compareOpls = (first: OplPanelModel, second: OplPanelModel): number => {
  const length = Math.min(first.name.length, second.name.length);

  const name1 = first.name.toLowerCase();
  const name2 = second.name.toLowerCase();

  for (let i = 0; i < length; i += 1) {
    const symbolWeight1 = calculateSymbolWeight(name1[i]);
    const symbolWeight2 = calculateSymbolWeight(name2[i]);

    if (symbolWeight1 > symbolWeight2) {
      return 1;
    }

    if (symbolWeight1 < symbolWeight2) {
      return -1;
    }

    if (name1[i] > name2[i]) {
      return 1;
    }

    if (name1[i] < name2[i]) {
      return -1;
    }
  }

  if (name1.length > name2.length) {
    return 1;
  }

  if (name1.length < name2.length) {
    return -1;
  }

  return 0;
};
