import {Injectable} from '@angular/core';
import {Action, createSelector, Selector, State, StateContext} from '@ngxs/store';
import {OplApiService} from '@matchsource/api/opl';
import {
  AddSourceToExistedOpl,
  AddSourceToOPL,
  CreateOpl,
  CreateOplWithSource,
  DeleteOpl,
  GetOpl,
  RemoveOplSource,
  RemoveOplSources,
  UpdateDefaultOpl,
  UpdateOpl,
  GetSsaOpl,
  ClearOpl,
  ClearOplVaccinationDetails,
  GetDefaultOpl,
  GetOpls,
  UpdateOplSourceOrderableStatus,
} from 'app/features/search-results/shared/store/opl/opl.actions';
import {Observable, of, zip} from 'rxjs';
import {catchError, filter, map, switchMap, tap} from 'rxjs/operators';
import {compareOpls} from './utils/opl.comparator';
import {OrderApiService} from '@matchsource/api/orders';
import cloneDeep from 'lodash-es/cloneDeep';
import {OplCordSourceModel, OplDonorSourceModel, OplPanelModel} from '@matchsource/models/opl';
import {OplSourceService} from 'app/features/search-results/shared/services/opl-source.service';
import {BLOOD_SRC_OPL_TYPES, OPL_BLOOD_TYPES, OPL_SOURCE_TYPES} from 'app/shared';
import {VaccinationsApiService} from '@matchsource/api/vaccinations';
import {VaccinationModel} from '@matchsource/models/vaccination';
import keyBy from 'lodash-es/keyBy';
import isEmpty from 'lodash-es/isEmpty';
import {ErrorModel, getErrorModelFromErrorResponse} from '@matchsource/api-utils';
import {Navigate} from 'ngxs-ui-router';
import {OplStateModel} from './opl.state.model';
import {BloodSourceType} from '@matchsource/models/source';
import {UserService} from '@matchsource/core';

const initState = (): OplStateModel => ({
  opl: null,
  opls: null,
  loading: false,
  sourceGuidToVaccinationMap: {},
  updateOplError: null,
  clearOplSourcesError: null,
  createOplError: null,
  deleteOplError: null,
  addSourceToOplError: null,
  deleteSourceFromOplError: null,
  createOrAddSourceToExistedOplError: null,
});

@State<OplStateModel>({
  name: 'oplSource',
  defaults: initState(),
})
@Injectable()
export class OplState {
  constructor(
    private readonly oplApiService: OplApiService,
    private readonly orderApi: OrderApiService,
    private readonly oplSourceService: OplSourceService,
    private readonly vaccinationsApiService: VaccinationsApiService,
    private readonly user: UserService
  ) {}

  @Selector([OplState])
  static loading(state: OplStateModel): boolean {
    return state.loading;
  }

  @Selector([OplState])
  static opls(state: OplStateModel): OplPanelModel[] {
    return state.opls;
  }

  @Selector([OplState])
  static opl(state: OplStateModel): OplPanelModel {
    return state.opl;
  }

  @Selector([OplState])
  static updateOplError(state: OplStateModel): ErrorModel {
    return state.updateOplError;
  }

  @Selector([OplState])
  static clearOplSourcesError(state: OplStateModel): ErrorModel {
    return state.clearOplSourcesError;
  }

  @Selector([OplState])
  static createOplError(state: OplStateModel): ErrorModel {
    return state.createOplError;
  }

  @Selector([OplState])
  static deleteOplError(state: OplStateModel): ErrorModel {
    return state.deleteOplError;
  }

  @Selector([OplState])
  static addSourceToOplError(state: OplStateModel): ErrorModel {
    return state.addSourceToOplError;
  }

  @Selector([OplState])
  static deleteSourceFromOplError(state: OplStateModel): ErrorModel {
    return state.deleteSourceFromOplError;
  }

  @Selector([OplState])
  static createOrAddSourceToExistedOplError(state: OplStateModel): ErrorModel {
    return state.createOrAddSourceToExistedOplError;
  }

  static isSourceAddedToOpl(id: MsApp.Guid, bloodType: string) {
    return createSelector([OplState], (state: OplStateModel) =>
      state.opl[bloodType].some((item: OplDonorSourceModel | OplCordSourceModel) => item.guid === id)
    );
  }

  @Action(GetOpls)
  getOpls(ctx: StateContext<OplStateModel>, {recipientGuid, sourceType}: GetOpls) {
    return this.oplApiService.getOpls(recipientGuid, sourceType).pipe(
      tap(opls => {
        ctx.patchState({
          opls: opls.sort((first, second) => compareOpls(first, second)),
        });
      })
    );
  }

  private getVaccinations(
    ctx: StateContext<OplStateModel>,
    opl: OplPanelModel,
    requestVaccinationDetails: boolean,
    repeatedRequest: boolean
  ): Observable<MsApp.Dictionary<VaccinationModel>> {
    const sourceGuids = opl.donors.map(donor => donor.guid);

    if (!sourceGuids.length || (!requestVaccinationDetails && !repeatedRequest)) {
      return of({});
    }

    if (!requestVaccinationDetails && repeatedRequest) {
      const storedVaccinationMap = ctx.getState().sourceGuidToVaccinationMap;
      return of(storedVaccinationMap);
    }

    return this.vaccinationsApiService.getVaccinations({sourceGuids}).pipe(
      map(vaccinations => keyBy(vaccinations, 'subjectGuid')),
      tap(vaccinations => {
        ctx.patchState({
          sourceGuidToVaccinationMap: vaccinations,
        });
      }),
      catchError(() => {
        ctx.patchState({
          sourceGuidToVaccinationMap: {},
        });
        return of({});
      })
    );
  }

  @Action(GetSsaOpl)
  getSsaOpl(ctx: StateContext<OplStateModel>, {id, sourceType, requestVaccinationDetails, repeatedRequest}: GetSsaOpl) {
    ctx.patchState({loading: true});
    return this.oplApiService.getOpl(id).pipe(
      tap(opl => {
        if (!this.user.isCHS() && !opl.owner && opl?.private) {
          ctx.patchState({loading: false});
          ctx.dispatch(new Navigate('dashboard.overview'));
        }
      }),
      filter(opl => this.user.isCHS() || opl.owner || !opl.private),
      switchMap(opl => {
        return zip(
          this.oplSourceService.getSources(opl, BloodSourceType.Donors).pipe(
            switchMap(sources => this.orderApi.getOrderableSources(opl.patientId, sources)),
            map(orderableSources => this.oplSourceService.formatSources(orderableSources, opl, BloodSourceType.Donors))
          ),
          this.oplSourceService.getSources(opl, BloodSourceType.Cords).pipe(
            switchMap(sources => this.orderApi.getOrderableSources(opl.patientId, sources)),
            map(orderableSources => this.oplSourceService.formatSources(orderableSources, opl, BloodSourceType.Cords))
          ),
          this.getVaccinations(ctx, opl, requestVaccinationDetails, repeatedRequest)
        );
      }),
      map(([opl, oplCords, vaccinations]) => {
        const donors = isEmpty(vaccinations)
          ? opl.donors
          : opl.donors.map(donor => ({
              ...donor,
              vaccination: vaccinations[donor.guid],
            }));
        return {...opl, donors, cords: oplCords.cords};
      }),
      tap(opl => {
        ctx.dispatch(new UpdateDefaultOpl(opl.id, opl.patientId, BLOOD_SRC_OPL_TYPES[sourceType]));
        ctx.patchState({
          opl,
          opls: this.oplSourceService.updateOpls(ctx, opl),
          loading: false,
        });
      })
    );
  }

  @Action(GetOpl)
  getOpl(ctx: StateContext<OplStateModel>, {id, sourceType, requestVaccinationDetails, repeatedRequest}: GetOpl) {
    const type = OPL_BLOOD_TYPES[sourceType];
    ctx.patchState({loading: true});
    return this.oplApiService.getOpl(id).pipe(
      switchMap(opl =>
        zip(
          this.oplSourceService.getSources(opl, type).pipe(
            switchMap(sources => this.orderApi.getOrderableSources(opl.patientId, sources)),
            map(orderableSources => this.oplSourceService.formatSources(orderableSources, opl, type))
          ),
          this.getVaccinations(ctx, opl, requestVaccinationDetails, repeatedRequest)
        )
      ),
      map(([opl, vaccinations]) => {
        const donors = isEmpty(vaccinations)
          ? opl.donors
          : opl.donors.map(donor => ({
              ...donor,
              vaccination: vaccinations[donor.guid],
            }));
        return {...opl, donors};
      }),
      tap(opl => {
        ctx.dispatch(new UpdateDefaultOpl(opl.id, opl.patientId, BLOOD_SRC_OPL_TYPES[sourceType]));
        ctx.patchState({
          opl,
          opls: this.oplSourceService.updateOpls(ctx, opl),
          loading: false,
        });
      })
    );
  }

  @Action(GetDefaultOpl)
  getDefaultOpl(
    ctx: StateContext<OplStateModel>,
    {recipientGuid, sourceType, isSsa, requestVaccinationDetails}: GetDefaultOpl
  ) {
    return this.oplApiService.getDefaultOpl(recipientGuid, sourceType).pipe(
      tap(opl => {
        if (opl?.id) {
          return isSsa
            ? ctx.dispatch(new GetSsaOpl(opl.id, OPL_SOURCE_TYPES[sourceType], {requestVaccinationDetails}))
            : ctx.dispatch(new GetOpl(opl.id, OPL_SOURCE_TYPES[sourceType], {requestVaccinationDetails}));
        }

        ctx.patchState({
          opl: null,
        });
      })
    );
  }

  @Action(UpdateOpl)
  updateOpl(ctx: StateContext<OplStateModel>, {id, opl, sourceType}: UpdateOpl) {
    ctx.patchState({updateOplError: null});
    return this.oplApiService.editOpl(opl).pipe(
      tap(() =>
        opl.isSSA
          ? ctx.dispatch(new GetSsaOpl(id, sourceType, {repeatedRequest: true}))
          : ctx.dispatch(new GetOpl(id, sourceType, {repeatedRequest: true}))
      ),
      catchError(error => {
        ctx.patchState({updateOplError: getErrorModelFromErrorResponse(error)});
        return of(null);
      })
    );
  }

  @Action(UpdateDefaultOpl)
  updateDefaultOpl(ctx: StateContext<OplStateModel>, {id, recipientGuid, sourceType}: UpdateDefaultOpl) {
    return this.oplApiService.saveOrUpdateDefaultOpl(id, recipientGuid, sourceType);
  }

  @Action(RemoveOplSource)
  removeOplSource(ctx: StateContext<OplStateModel>, {id, guid, sourceType}: RemoveOplSource) {
    ctx.patchState({deleteSourceFromOplError: null});
    const {opl} = ctx.getState();
    const oplCopy = cloneDeep(opl);
    return this.oplApiService.removeSourceFromOpl(id, guid).pipe(
      tap(() => {
        const filteredOpl = this.oplSourceService.filterOplSources(oplCopy, guid, sourceType);
        ctx.patchState({
          opl: filteredOpl,
          opls: this.oplSourceService.updateOpls(ctx, filteredOpl),
        });
      }),
      catchError(error => {
        ctx.patchState({deleteSourceFromOplError: getErrorModelFromErrorResponse(error)});
        return of(null);
      })
    );
  }

  @Action(DeleteOpl)
  deleteOpl(ctx: StateContext<OplStateModel>, {id, recipientGuid, sourceType}: DeleteOpl) {
    return this.oplApiService.deleteOpl(id).pipe(
      tap(() => {
        // it is called with donors or DONOR value, depending on the place called. TODO : needs to fix typization
        ctx.dispatch(new GetOpls(recipientGuid, BLOOD_SRC_OPL_TYPES[sourceType] || sourceType));
        ctx.patchState({
          opl: null,
        });
      }),
      catchError(error => {
        ctx.patchState({deleteOplError: getErrorModelFromErrorResponse(error)});
        return of(null);
      })
    );
  }

  @Action(UpdateOplSourceOrderableStatus)
  updateOplSourceOrderableStatus(ctx: StateContext<OplStateModel>, action: UpdateOplSourceOrderableStatus): void {
    const state = ctx.getState();
    type DonorOrCordOplSourceModel = OplDonorSourceModel | OplCordSourceModel;
    const getModifiedSourceModel = <TSourceModel extends DonorOrCordOplSourceModel>(
      sourceModel: TSourceModel
    ): TSourceModel => {
      // The source of opl source model could be null - only the OPLs that were selected by the user have source
      if (sourceModel.source?.id !== action.sourceId) {
        return sourceModel;
      }
      const modifiedSourceModel: TSourceModel = {
        ...sourceModel,
        source: {
          ...sourceModel.source,
          statusOverride: action.statusOverride,
          orderableStatus: action.orderableStatus,
        },
      };
      return modifiedSourceModel;
    };

    const getModifiedOplModel = (opl: OplPanelModel): OplPanelModel => {
      // The opl could be null if there are no OPLs created
      if (!opl) {
        return opl;
      }
      const newOpl: OplPanelModel = {
        ...opl,
      };
      // Find where the source is - in donors or cords
      if (opl.donors?.some(x => x.source?.id === action.sourceId)) {
        newOpl.donors = opl.donors.map(donorSourceModel => getModifiedSourceModel(donorSourceModel));
      } else if (opl.cords?.some(x => x.source?.id === action.sourceId)) {
        newOpl.cords = opl.cords?.map(cordSourceModel => getModifiedSourceModel(cordSourceModel));
      }
      return newOpl;
    };

    ctx.patchState({
      // This is the currently selected OPL
      opl: getModifiedOplModel(state.opl),
      // Technically it is not needed to update all the OPLs because once the user selects OPL,
      // it will be reloaded and will have the most recent data. Updating would be neded only if
      // other code needs to read all the OPLs but they usually don't have sources in donors/cords arrays
      // unless they are loaded from the server. That's why we will skip updating this array
      // opls: state.opls?.map(opl => getModifiedOplModel(opl)),
    });
  }

  @Action(RemoveOplSources)
  removeOplSources(ctx: StateContext<OplStateModel>, {id, sourceType}: RemoveOplSources) {
    return this.oplApiService.removeSourcesFromOpl(id).pipe(
      tap(() => {
        ctx.dispatch(new GetOpl(id, sourceType, {repeatedRequest: true}));
      }),
      catchError(error => {
        ctx.patchState({clearOplSourcesError: getErrorModelFromErrorResponse(error)});
        return of(null);
      })
    );
  }

  @Action(CreateOpl)
  createOpl(ctx: StateContext<OplStateModel>, {opl, recipientGuid, sourceType}: CreateOpl) {
    ctx.patchState({createOplError: null});
    return this.oplApiService.createOpl(opl).pipe(
      tap(response => {
        ctx.dispatch([
          new GetOpls(recipientGuid, BLOOD_SRC_OPL_TYPES[sourceType]),
          new GetOpl(response.id, sourceType),
        ]);
      }),
      catchError(error => {
        ctx.patchState({createOplError: getErrorModelFromErrorResponse(error)});
        return of(null);
      })
    );
  }

  @Action(AddSourceToOPL)
  addSource(ctx: StateContext<OplStateModel>, {source, oplId, oplSource}: AddSourceToOPL) {
    ctx.patchState({addSourceToOplError: null});
    return this.oplApiService.addSourceToOPL(source, oplId).pipe(
      switchMap(() => {
        return this.oplApiService
          .getOpl(oplId)
          .pipe(map(item => this.oplSourceService.fillOplWithSources(item, ctx, source, oplSource)));
      }),
      tap(opl => {
        const updatedOpls = this.oplSourceService.updateOpls(ctx, opl);
        ctx.patchState({
          opl,
          opls: updatedOpls.sort((first, second) => compareOpls(first, second)),
        });
      }),
      catchError(error => {
        ctx.patchState({addSourceToOplError: getErrorModelFromErrorResponse(error)});
        return of(null);
      })
    );
  }

  @Action(CreateOplWithSource)
  createOplWithSource(ctx: StateContext<OplStateModel>, {opl, recipientGuid, source}: CreateOplWithSource) {
    ctx.patchState({createOrAddSourceToExistedOplError: null});
    return this.oplApiService.createOpl(opl).pipe(
      tap(response => {
        ctx.dispatch([
          new GetOpls(recipientGuid, source.sourceType as 'CORD' | 'DONOR'),
          new GetOpl(response.id, OPL_SOURCE_TYPES[source.sourceType]),
          new AddSourceToExistedOpl(source, response.id),
        ]);
      }),
      catchError(error => {
        ctx.patchState({createOrAddSourceToExistedOplError: getErrorModelFromErrorResponse(error)});
        return of(null);
      })
    );
  }

  @Action(AddSourceToExistedOpl)
  addSourceToExistedOpl(ctx: StateContext<OplStateModel>, {source, oplId}: AddSourceToExistedOpl) {
    ctx.patchState({createOrAddSourceToExistedOplError: null});
    return this.oplApiService.addSourceToOPL(source, oplId).pipe(
      tap(() => {
        ctx.dispatch(new GetOpl(oplId, OPL_SOURCE_TYPES[source.sourceType], {repeatedRequest: true}));
      }),
      catchError(error => {
        ctx.patchState({createOrAddSourceToExistedOplError: getErrorModelFromErrorResponse(error)});
        return of(null);
      })
    );
  }

  @Action(ClearOpl)
  clearOpl(ctx: StateContext<OplStateModel>) {
    ctx.setState(initState());
  }

  @Action(ClearOplVaccinationDetails)
  clearVaccinations(ctx: StateContext<OplStateModel>) {
    ctx.patchState({
      sourceGuidToVaccinationMap: {},
    });
  }
}
